var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "tipjar", staticClass: "tip-jar" }, [
    _c(
      "div",
      {
        staticClass: "venmo",
        on: {
          click: function ($event) {
            return _vm.showVenmoCode()
          },
        },
      },
      [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/venmo.svg") },
        }),
      ]
    ),
    _c("div", { staticClass: "paypal", on: { click: _vm.openPaypalWindow } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/paypal.svg") },
      }),
    ]),
    _c("div", { staticClass: "stripe" }, [_vm._v("Tip Your Host")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }